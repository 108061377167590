import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  FormControlLabel,
  MailIcon,
  Radio,
  RadioGroup,
  Switch,
  Input,
  Tooltip,
  WarningAmberOutlined,
  Button,
} from 'ui-kit';
import { type ReactNode, useState } from 'react';

enum TabIdEnum {
  Slack = 'slack',
  Email = 'email',
  Teams = 'teams',
}

enum NotifyAboutEnum {
  Urgent = 'urgent',
  Custom = 'custom',
}

interface NotificationData {
  slackActive: boolean;
  slackChannel: string;
  slackNotificationEmail: string;
  notifyAbout: NotifyAboutEnum;
  workflowExecutionCompleted: boolean;
  failedExecutions: boolean;
  successfulExecutions: boolean;
  improvementDetected: boolean;
}

export default function Notifications() {
  const [selectedTab, setSelectedTab] = useState<TabIdEnum>(TabIdEnum.Slack);

  const [notificationData, setNotificationData] = useState<NotificationData>({
    slackActive: true,
    slackChannel: '',
    slackNotificationEmail: '',
    notifyAbout: NotifyAboutEnum.Urgent,
    failedExecutions: true,
    improvementDetected: true,
    successfulExecutions: false,
    workflowExecutionCompleted: false,
  });

  const updateNotificationData = (
    key: keyof NotificationData,
    value: string | boolean,
  ) => {
    // Toggle all notifications when custom is selected and disable all notifications except the urgent ones when urgent is selected
    if (key === 'notifyAbout') {
      if (value === NotifyAboutEnum.Custom) {
        setNotificationData((prev) => ({
          ...prev,
          [key]: value,
          failedExecutions: true,
          improvementDetected: true,
          successfulExecutions: true,
          workflowExecutionCompleted: true,
        }));
      }
      if (value === NotifyAboutEnum.Urgent) {
        setNotificationData((prev) => ({
          ...prev,
          [key]: value,
          failedExecutions: true,
          improvementDetected: true,
          successfulExecutions: false,
          workflowExecutionCompleted: false,
        }));
      }
      return;
    }

    // Toggle nested values when workflowExecutionCompleted is selected
    if (key === 'workflowExecutionCompleted') {
      setNotificationData((prev) => ({
        ...prev,
        [key]: Boolean(value),
        failedExecutions: Boolean(value),
        successfulExecutions: Boolean(value),
        notifyAbout: NotifyAboutEnum.Custom,
      }));
      return;
    }

    // Toggle nested values when failedExecutions or successfulExecutions is selected
    if (key === 'failedExecutions' || key === 'successfulExecutions') {
      if (value && !notificationData.workflowExecutionCompleted) {
        setNotificationData((prev) => ({
          ...prev,
          [key]: value,
          workflowExecutionCompleted: true,
          ...(key === 'failedExecutions' &&
          !notificationData.successfulExecutions &&
          notificationData.improvementDetected
            ? { notifyAbout: NotifyAboutEnum.Urgent }
            : { notifyAbout: NotifyAboutEnum.Custom }),
        }));
        return;
      }

      const togglingBothOff =
        notificationData.workflowExecutionCompleted &&
        ((!notificationData.failedExecutions &&
          !value &&
          key === 'successfulExecutions') ||
          (!notificationData.successfulExecutions &&
            !value &&
            key === 'failedExecutions'));

      if (togglingBothOff) {
        setNotificationData((prev) => ({
          ...prev,
          [key]: value,
          workflowExecutionCompleted: false,
          notifyAbout: NotifyAboutEnum.Custom,
        }));
        return;
      }

      // Toggle urgent to on if all the conditions it will turn on are met
      if (
        key === 'successfulExecutions' &&
        !value &&
        notificationData.improvementDetected
      ) {
        setNotificationData((prev) => ({
          ...prev,
          [key]: Boolean(value),
          notifyAbout: NotifyAboutEnum.Urgent,
        }));
        return;
      }
    }

    setNotificationData((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className="flex py-8 space-x-10 gap-6">
      <div>
        <Box sx={{ display: 'flex' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={(_, newValue) => {
              setSelectedTab(newValue as TabIdEnum);
            }}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              '& .MuiTab-root': {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap: '12px',
                minHeight: '48px',
                textAlign: 'left',
                paddingRight: '6rem',
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#2196F3',
              },
              '& .Mui-selected': {
                color: '#2196F3 !important',
              },
            }}
          >
            <Tab
              icon={
                <img src="/slack_icon.png" alt="Slack" width={24} height={24} />
              }
              label="SLACK"
              iconPosition="start"
              value={TabIdEnum.Slack}
            />

            <Tooltip
              arrow
              placement="right"
              title="Customizable notifications here coming soon! Contact sales if you are interested."
            >
              <Tab
                icon={<MailIcon />}
                label="EMAIL"
                iconPosition="start"
                value={TabIdEnum.Email}
                disabled
              />
            </Tooltip>

            <Tooltip
              arrow
              placement="right"
              title="Customizable notifications here coming soon! Contact sales if you are interested."
            >
              <Tab
                icon={
                  <img
                    src="/teams_icon.png"
                    alt="teams"
                    width={24}
                    height={24}
                  />
                }
                label="TEAMS"
                iconPosition="start"
                value={TabIdEnum.Teams}
                disabled
              />
            </Tooltip>
          </Tabs>
        </Box>
      </div>
      <div className="w-full max-w-[800px] flex flex-col gap-5">
        <div className="bg-[#FFF4E5] flex space-x-2 px-4 py-3 rounded text-sm">
          <WarningAmberOutlined className="!w-5 !h-5 !text-warning !mt-0.5" />
          <span className="text-[#663C00]">
            For workflows with high execution volume, we recommend turning off
            notifications or enabling only urgent ones. Excessive notifications
            may trigger Slack's rate limits, causing delays in delivery.
          </span>
        </div>

        <SwitchRow
          checked={notificationData.slackActive}
          onChange={(newChecked) => {
            updateNotificationData('slackActive', newChecked);
          }}
        >
          <h2 className="font-medium text-xl text-info-dark">
            Slack notifications
          </h2>
          <p className="text-sm text-gray-500 !mt-2">
            Setup notifications for your workflow
          </p>
        </SwitchRow>

        <div>
          <h2 className="font-medium text-lg text-info-dark mb-6 ">
            Slack channel
          </h2>
          <span className="text-sm text-info-dark bg-[#F5F5F5] rounded-lg p-4">
            #sola-chapter-notifs
          </span>
        </div>

        <div className="pt-2">
          <p className="text-sm text-gray-500 !mt-2 mb-2">
            Enter the email addresses to receive Slack notifications. You can
            add multiple addresses, separated by commas.
          </p>
          <Input
            placeholder="Enter email addresses (e.g., user@sola.ai, user2@sola.ai)"
            label="Notification email recipients"
            floatingLabel
          />
        </div>

        <div>
          <h2 className="font-medium text-xl text-info-dark mt-5">
            Notify about
          </h2>
          <RadioGroup
            defaultValue={NotifyAboutEnum.Urgent}
            name="radio-buttons-group"
            onChange={(_, val) => {
              updateNotificationData('notifyAbout', val as NotifyAboutEnum);
            }}
            value={notificationData.notifyAbout}
          >
            <FormControlLabel
              control={<Radio color="secondary" />}
              label={<span className="text-info-dark  ">Only urgent</span>}
              value={NotifyAboutEnum.Urgent}
            />
            <FormControlLabel
              control={<Radio color="secondary" />}
              label={
                <span className="text-info-dark  ">
                  Customize notifications
                </span>
              }
              value={NotifyAboutEnum.Custom}
            />
          </RadioGroup>
        </div>

        <div>
          <SwitchRow
            checked={notificationData.workflowExecutionCompleted}
            onChange={(newChecked) => {
              updateNotificationData('workflowExecutionCompleted', newChecked);
            }}
          >
            <h2 className="font-medium text-lg text-info-dark">
              Workflow execution completed
            </h2>
            <p className="text-sm text-gray-500 !mt-2">
              Receive a notification for every completed workflow execution.
            </p>
          </SwitchRow>

          <SwitchRow
            checked={notificationData.failedExecutions}
            onChange={(newChecked) => {
              updateNotificationData('failedExecutions', newChecked);
            }}
          >
            <h2 className=" text-info-dark pl-5">Failed executions</h2>
          </SwitchRow>

          <SwitchRow
            checked={notificationData.successfulExecutions}
            onChange={(newChecked) => {
              updateNotificationData('successfulExecutions', newChecked);
            }}
          >
            <h2 className=" text-info-dark pl-5">Successful executions</h2>
          </SwitchRow>
        </div>

        <SwitchRow
          checked={notificationData.improvementDetected}
          onChange={(newChecked) => {
            updateNotificationData('improvementDetected', newChecked);
          }}
        >
          <h2 className="font-medium text-lg text-info-dark">
            Improvement detected
          </h2>
          <p className="text-sm text-gray-500 !mt-2">
            Receive a notification when the bot has implemented or detected a
            workflow improvement for your approval.
          </p>
        </SwitchRow>

        <div className="flex items-center space-x-4">
          <Button
            color="secondary"
            // disabled={!allowSave || loading}
            variant="contained"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={() => {}}
          >
            Save Changes
          </Button>
          <Button
            color="secondary"
            // disabled={loading}
            variant="outlined"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={() => {}}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

function SwitchRow({
  checked,
  onChange,
  children,
}: {
  checked: boolean;
  onChange: (newChecked: boolean) => void;
  children: ReactNode;
}) {
  return (
    <div className="flex items-center gap-2 w-full justify-between mb-5">
      <div>{children}</div>
      <Switch
        color="secondary"
        checked={checked}
        onChange={(_, newChecked) => {
          onChange(newChecked);
        }}
      />
    </div>
  );
}
